<div class="common-form-field-label">
    <h2 class="type-18 m-type-15"><slot name="label" /></h2>
    <p class="color-text-60 type-15 m-type-14"><slot name="subtitle" /></p>
</div>

<style>
    .common-form-field-label {
        display: flex;
        flex-direction: column;
        gap: 6px;
        text-align: left;
    }
</style>
