<script>
	import contact from "../../../stores/contact.js";
	import Header from "../../Header.svelte";
	import CalendarLinks from "../../../../common/CalendarLinks.svelte";
	import Features from "../../Features.svelte";
	import { simpleFormattedLuxonTime } from "../../../../common/time.js";
	import iconCalendar from "../../../../../images/icons/calendar.svg";

	$: formattedDate = $contact.data.slot.toLocaleString({
		weekday: "long",
		month: "long",
		day: "numeric",
		year: "numeric"
	});

	$: calendarDescription =
		"During this 15 minute phone call a Samara Specialist will answer your questions and help you choose a Backyard configuration that best suits your needs.";
</script>

<div class="success">
	<div class="confirmation-icon">
		<img
			src="/assets/images/icons/success.svg"
			alt="Checkmark"
			width="44"
			height="44" />
		<div class="spacer-24" />
	</div>

	<Header>
		<span slot="title">Your phone call is confirmed</span>
		<span slot="subtitle">
			You’ll receive a confirmation by email with all of the details for
			your&nbsp;call.
		</span>
	</Header>

	<div class="spacer-50 m-spacer-32" />
	<Features
		leftText={simpleFormattedLuxonTime($contact.data.slot)}
		rightIcon={iconCalendar}
		rightText={formattedDate} />

	<div class="spacer-50 m-spacer-32" />
	<div class="confirmation-download">
		<CalendarLinks
			variant="centered"
			date={$contact.data.slot}
			title="Quick phone call with Samara"
			description={calendarDescription}
			address="Phone call"
			duration={15} />
	</div>
</div>

<style>
	.confirmation-icon {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
</style>
