<script>
	import iconCheckmark from "../../images/icons/checkmark-white.svg";

	export let options = [];
	export let name;
	export let store;
	export let className = "";
	export let allowsMultiple = false;
	export let withCheckmark = false;

	$: isSelected = (option) => {
		const value = $store && $store.data[name];

		if (value && allowsMultiple) {
			return value.includes(option);
		}

		return value === option;
	};

	function didSelectOption(option) {
		if (allowsMultiple) {
			const current = $store.data[name] ?? [];
			const value = current.includes(option)
				? current.filter((value) => value !== option)
				: [...current, option];

			store.updateData(name, value);
		} else {
			store.updateData(name, option);
		}
	}
</script>

<div class={`common-form-button-select type-18 m-type-16 ${className}`}>
	{#each options as option}
		<button
			on:click={() => didSelectOption(option)}
			class:selected={isSelected(option)}
			class="type-15 m-type-14">
			{option}
			{#if withCheckmark && isSelected(option)}
				{@html iconCheckmark}
			{/if}
		</button>
	{/each}
</div>

<style>
	.common-form-button-select {
		display: flex;
		gap: 12px;
	}

	@media (max-width: 768px) {
		.common-form-button-select {
			gap: 9px;
		}
	}

	.common-form-button-select button {
		display: flexx;
		flex-grow: 1;
		flex-basis: 0;
		justify-content: center;
		align-items: center;
		gap: 6px;
		appearance: none;
		margin: 0;
		border: none;
		border-radius: 12px;
		background-color: rgba(0, 0, 0, 0.06);
		padding: 18px 0;
		color: #000000;
	}

	@media (max-width: 768px) {
		.common-form-button-select button {
			padding: 11px 0;
		}
	}

	:global(.common-form-button-select button svg) {
		position: relative;
		top: -0.05em;
		width: auto;
		height: 0.8em;
	}

	.common-form-button-select button.selected {
		background-color: #000000;
		color: #ffffff;
	}

	@media (hover: hover) {
		.common-form-button-select-inverted button:hover {
			background-color: rgba(255, 255, 255, 0.4);
		}
	}

	.common-form-button-select-inverted button {
		background-color: rgba(255, 255, 255, 0.2);
		color: #ffffff;
	}

	.common-form-button-select-inverted button.selected {
		background-color: rgba(255, 255, 255, 1);
		color: #000000;
	}
</style>
